import { PATH, ACCESS_PERMISSIONS } from "constants/index";
import { PageCardDetails } from "types";
import { PARTY } from "constants/index";

export const pageCardDetails = ({
  isDidoDemo,
  isT360,
  isUserPermission,
  isUserPartOfOrganization
}: {
  isDidoDemo: boolean;
  isT360: boolean;
  isUserPermission: (permissions: string[]) => boolean;
  isUserPartOfOrganization: (organizations: string[]) => boolean;
}): PageCardDetails[] => {
  return [
    {
      link: PATH.SEARCH,
      iconURL: "/assets/party-profile-search-icon-grey.svg",
      iconURLHover: "/assets/party-profile-search-icon-purple.svg",
      text: "Search",
      isUserAllowed: isT360,
    },
    //  TODO: to be checked and enabled based on role in the future
    
    // {
    //   link: PATH.FUND_SEARCH,
    //   iconURL: "/assets/party-profile-search-icon-grey.svg",
    //   iconURLHover: "/assets/party-profile-search-icon-purple.svg",
    //   text: "Funds Search",
    //   isUserAllowed: true,
    // },
    {
      link: PATH.ALERT,
      iconURL: "/assets/checkmate-landing-page-menu-icon-grey.svg",
      iconURLHover: "/assets/checkmate-landing-page-menu-icon-purple.svg",
      text: "Alert",
      isUserAllowed: isT360,
    },
    {
      link: PATH.ADMIN_ALICE_REQUEST,
      iconURL: "/assets/party-manager-admin-room-grey.svg",
      iconURLHover: "/assets/party-manager-admin-room-purple.svg",
      text: "ALICE Admin",
      isUserAllowed: isT360,
    },
    {
      link: PATH.CLIENT_ALICE_REQUEST,
      iconURL: "/assets/party-manager-admin-room-grey.svg",
      iconURLHover: "/assets/party-manager-admin-room-purple.svg",
      text: "Client Room",
      isUserAllowed: isUserPermission([ACCESS_PERMISSIONS.CLIENT_CONSOLE_ACCESS]),
    },
    {
      link: PATH.DIDO_DEMO_INTERACTIVE,
      iconURL: "/assets/party-manager-admin-room-purple.svg",
      iconURLHover: "/assets/party-manager-admin-room-purple.svg",
      text: "DIDO's Demo",
      isUserAllowed: isDidoDemo,
    },
    {
      link: PATH.INVESTORS_MANAGER_DASHBOARD,
      iconURL: isUserPartOfOrganization([PARTY.HUBWISE]) ? "/assets/ssnc-logo.svg" : isUserPartOfOrganization([PARTY.HALO]) ? "/assets/halo-invest-logo-grey.svg" : "/assets/fund-party-manager.png",
      iconURLHover: isUserPartOfOrganization([PARTY.HUBWISE]) ? "/assets/ssnc-logo.svg" : isUserPartOfOrganization([PARTY.HALO]) ? "/assets/halo-invest-logo-purple.svg" : "/assets/fund-party-manager.png",
      text: "Fund Party Manager",
      isUserAllowed: isUserPermission([ACCESS_PERMISSIONS.INVESTORS_MANAGER_ACCESS]),
    },
    {
      link: PATH.INSTRUMENTS_MANAGER_DASHBOARD,
      iconURL: "/assets/fund-party-manager.png",
      iconURLHover: "/assets/fund-party-manager.png",
      text: isUserPartOfOrganization([PARTY.T360]) ? "FPM - FM Dashboard" : "Fund Party Manager",
      isUserAllowed: isUserPermission([ACCESS_PERMISSIONS.INSTRUMENTS_MANAGER_ACCESS]),
    }
  ];
};

export const pageDetails = ({
  isDidoDemo,
  isT360,
  isUserPermission,
  isUserPartOfOrganization
}: {
  isDidoDemo: boolean;
  isT360: boolean;
  isUserPermission: (permissions: string[]) => boolean;
  isUserPartOfOrganization: (organizations: string[]) => boolean;
}): PageCardDetails[] => {
 return pageCardDetails({ isDidoDemo, isT360, isUserPermission, isUserPartOfOrganization })
};
