import { IframeCardDetails } from "types/ecp";
import ReportData from "../data/report.json";

export const PARTY_MAP = {
  barclays_party: "GB00048839",
  deutsche_bank_party: "DEFEB13216",
  hsbc_party: "GB00617987",
  t360_party: "DEFEB13216",
};

export enum PARTY {
  HUBWISE = "hubwise_party",
  T360 = "t360_party",
  HALO = "halo_party"
}

export enum ACCESS_PERMISSIONS {
  FUND_PARTY_MANAGER = "fund_party_manager_service_access",
  PARTY_MANAGER = "party_manager_service_access",
  DIDO_DEMO = "dido_demo_access",
  ADMIN_CONSOLE_ACCESS= "admin_console_access",
  CLIENT_CONSOLE_ACCESS= "client_console_access",
  INVESTORS_MANAGER_ACCESS= "investments_manager_service_access",
  INSTRUMENTS_MANAGER_ACCESS= "instruments_manager_service_access"
}

export enum USER_ROLES {
  GENERIC_MANAGER = "generic_manager_role",
  CCID_MANAGER = "ccid_manager_role",
  ALICE_TICKET_MANAGER= "alice_ticket_manager_role",
  ALICE_TICKET_USER = "alice_ticket_user_role",
  SSI_USER= "ssi_user_role",
  CLIENT_REQUEST_TEMPLATE_MANAGER = "client_request_template_manager_role",
  REQUEST_MANAGER = "request_manager_role",
  BUYLIST_MANAGER = "buylist_manager_role",
  BASIC_USER_ROLE = "basic_user_role",
  TESTING_CEP = "testing_cep_role",
  GENERAL_COMPANY_PARTY = "general_company_party"
}

export const PARTY_ID_BIC_MAP: { [key: string]: string } = {
  GB00617987: "HSBCGB22XXX",
  GB00014259: "MIDLGB22XXX",
  GB01026167: "BARCGB22XXX",
  GB09740322: "BUKBGB22XXX",
  DEFEB13216: "DEUTDEFFXXX",
  US134941247: "BKTRUS33XXX",
};

export const DASHBOARD_BAR_TITLE = {
  BANKS: "Banks",
  GOV_RELATED_INST: "Govt-Related Inst",
  FINANCIAL_INST: "Financial Institution",
};

export const ROLE = {
  ADMIN: "admin",
  CLIENT: "client/",
  DIDO: "dido",
  FPM: "fpm"
};

export enum COLOR_MODE {
  LIGHT = "light",
  DARK = "dark",
}

export enum CrownAlertSteps {
  FEEDBACK = "Feedback",
  DATAENQUIRY = "DataEnquiry",
  CLOSE = "Close",
}

export enum CHART_TYPE {
  PIE = "",
  GEO = "GeoChart",
}

export enum GRAPH_TYPE {
  SHO = "SHO",
  SUB = "SUB",
  GUO = "GUO",
  DUO = "DUO",
  CY_SHO = "cySHO",
  CY_CUB = "cySUB",
}

export enum PATH {
  SEARCH = "/search",
  SEARCH_RESULT = "/search-results",
  FUND_SEARCH = "/funds/search",
  FUND_SEARCH_RESULT = "/funds/search-results",
  FUND_ALERT = "/funds/alert",
  ALERT = "/alert",
  ADMIN_ALICE_REQUEST = "/admin/alice-requests",
  CLIENT_ALICE_REQUEST = "/client/alice-requests",
  ADMIN = "/admin",
  FUNDS = "/funds",
  INDEX = "/",
  SIGNOUT = "signout",
  CLIENT = "/client",
  DIDO_DEMO = "/dido-demo",
  DIDO_DEMO_INTERACTIVE = "/dido-demo/interactive",
  PARTY = "/party",
  FUND_PARTY_MANAGER= "/fpm",
  FUND_PARTY_MANAGER_INVESTORS= "fpm/investments-manager",
  FUND_PARTY_MANAGER_INSTRUMENTS= "fpm/instruments-manager",
  FUND_PARTY_MANAGER_MY_VIEW= "/fpm/investments-manager/dashboard/my-view",
  FUND_PARTY_MANAGER_ONBOARDING= "/fpm/investments-manager/dashboard/onboarding",
  FUND_PARTY_MANAGER_FUND_MANAGER= "/fpm/investments-manager/dashboard/fund-manager",
  FUND_PARTY_MANAGER_MASTER_BUYLIST= "/fpm/investments-manager/dashboard/buylist",
  FUND_PARTY_MANAGER_AGENT_CODES= "/fpm/investments-manager/dashboard/ssi",
  FUND_PARTY_MANAGER_CLIENT_MANAGER= "/fpm/investments-manager/dashboard/c-manager",
  FUND_PARTY_MANAGER_REPORTS= "/fpm/investments-manager/dashboard/reports",
  INVESTORS_MANAGER_DASHBOARD= "/fpm/investments-manager/dashboard",
  INSTRUMENTS_MANAGER_DASHBOARD= "/fpm/instruments-manager/dashboard",
  FUND_PARTY_MANAGER_ALICE_REQUEST = "/fpm/investments-manager/dashboard/alice-requests",
  CLIENT_REQUEST_TEMPLATE = "investments-manager/sp/crt",
  FUND_DATA_REQUEST= "/fpm/instruments-manager/sp/fdr"
}

export enum META_DATA {
  P_ID = "pid",
  USER_ID = "user_id",
  TRANSACTION_ID = "transaction_id",
  DATE_TIME = "date_time",
}

export enum TRANSACTION_STATUS {
  APPROVED = "approved",
  IN_PROGRESS = "in-progress",
  DENIED = "denied",
}

export const PARTY_ID = "GB00617987";

export enum DIRECTION {
  TOP = "top",
  BOTTOM = "bottom",
  RIGHT = "right",
  LEFT = "left",
}

export enum StatusProps {
  ERROR = "error",
  SUCCESS = "success",
}

export const REPORT_XLSX_DATA = `data:text/xlsx;chatset=utf-8,${encodeURIComponent(
  JSON.stringify(ReportData),
)}`;

export const IFRAME_CARD_DETAILS: IframeCardDetails[] = [
  {
    // domain: "60207.p.symphony.com",
    streamId: "WbpHBL0o1GNNVBZIUIMRoH///nnXpQu6dA==",
    chatName: "first",
  },
  {
    // domain: "60207.p.symphony.com",
    streamId: "zhJ1rZJVJVVAcsoWyzwEy3///nnXpgJbdA==",
    chatName: "second",
  },
];
