import styled from "styled-components";
import { Link } from "react-router-dom";

export const MasterBuylistContainer = styled.div`
  height: 100%;
  padding: 32px;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

export const FundManagersListContainer= styled.div`
   width: 100%;
  a {
    text-decoration: none;
  }
  p {
    color: ${({ theme }) => theme.colors.DARK[500]};
  }
  text-wrap: wrap;
`;

export const Title= styled.div`
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  font-weight: 700;
`;

export const TableContainer = styled.div`
  max-width: 100%;
  margin-top:30px;
  max-height: 55vh;
  overflow-y: auto;
`;

export const LinkToFundManagerProfile= styled(Link)`
  text-decoration: none;
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  margin-top: 20px;
`;

export const Thead = styled.thead`
  margin-bottom: 13px;
`;

export const Th = styled.th`
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  font-weight: 600;
  padding: 0 24px;
  padding-bottom: 12px;
  text-wrap: nowrap;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
`;

export const Tr = styled.tr<{ body?: boolean }>`
  text-align: center;
  height: ${(props) => (props.body === true ? "72px" : "")};
  cursor: ${(props) => (props.body === true ? "pointer" : "")};
`;

export const Td = styled.td<{ align?: string }>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  line-height: 20px;
  padding: 0 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  max-width: 200px;
  width: min-content;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: left;
`;

export const FundNameTd= styled.td`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  line-height: 20px;
  padding: 0 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  text-align: left;
  text-wrap: nowrap;

`;

export const ExtraDetailsHeaderSection= styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkToFundManagerProfileHolder= styled.div`
  display: flex;
  flex-direction: row;
`;

export const FundManagerLinkTitle= styled.div`
  padding-right: 12px;
`;