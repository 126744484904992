import { FunctionComponent } from "react";
import {
    BottomContentContainer,
    FiltersContainer,
    SearchWrapper,
    TableContainer,
    Th
} from "styles/funds/Onboarding.styled";
import useFilters from "hooks/useFilter";
import { Search } from "../../filter/Search";
import { 
    Table,
    Thead,
    Tr,
    Tbody,
    Td,
    SpanWrapper,
    EmptySpan,
    TableDate,
    TableTime,
    TableLink
} from "styles/admin/AdminTable.styled";
import { format } from "date-fns";
import { PATH } from "../../../constants/index";
import { ClientRequestTemplateType } from "types/party";

const ClientRequestsOnboardingTable: FunctionComponent<{data: ClientRequestTemplateType[]}> =  ({data}) => {

    const { filteredData, setFilter } = useFilters(data);
    const setData = (str: string) => {
        setFilter({ search: str });
    };
    
    return (
        <BottomContentContainer>
            <FiltersContainer>
                <SearchWrapper>
                    <Search setData={setData} />
                </SearchWrapper>
            </FiltersContainer>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            { window.innerWidth > 500 && <Th>Client Channel ID</Th> }
                            <Th>Client Channel Name</Th>
                            { window.innerWidth > 500 && <Th>xxxxxx</Th> }
                            { window.innerWidth > 769 && <Th>Project Team</Th> }
                            { window.innerWidth > 769 && <Th>Last Updated</Th> }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredData.map((ccid,index) => (
      
                            <Tr key={index} body data-testid="row">
                                { window.innerWidth > 500 && 
                                    <Td>
                                        <TableLink to={`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${ccid.id}`} >
                                            {ccid.id}
                                        </TableLink>
                                    </Td> 
                                }
                                <Td>
                                    <TableLink to={`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${ccid.id}`} >
                                        {ccid["Party Legal Name"]}
                                    </TableLink>
                                </Td>
                                { window.innerWidth > 500 && 
                                    <Td>
                                        <TableLink to={`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${ccid.id}`} >
                                            {ccid["Legal Name"]}
                                        </TableLink>
                                    </Td> 
                                }
                                { window.innerWidth > 769 && 
                                    <Td>
                                        <TableLink to={`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${ccid.id}`} >
                                            {ccid["Legal Name"]} 
                                        </TableLink>
                                    </Td> 
                                }
                                { window.innerWidth > 769 && 
                                    <Td>
                                        <TableLink to={`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${ccid.id}`} >
                                            <EmptySpan>
                                                <SpanWrapper>
                                                    <TableDate>
                                                    {format(new Date(ccid["Last Update Datetime"] ? ccid["Last Update Datetime"] : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)"), "dd MMM yyy")}
                                                    <br />
                                                    </TableDate>
                                                    <TableTime>{format(new Date(new Date(ccid["Last Update Datetime"] ? ccid["Last Update Datetime"] : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)")), "HH:mm")}</TableTime>
                                                </SpanWrapper>
                                            </EmptySpan>
                                        </TableLink>
                                    </Td> 
                                }
                            </Tr> 
                        ))}
                        
                    </Tbody>
                </Table>
            </TableContainer>
        </BottomContentContainer>
    )
};

export default ClientRequestsOnboardingTable;