import styled from "styled-components";
import MenuIcon from "@material-ui/icons//Menu";
import { HeaderStyle, HeaderType } from "../../types";

export const t360Logo = `${process.env.PUBLIC_URL}/assets/t360-header-logo.svg`;
export const t360LogoPurple = `${process.env.PUBLIC_URL}/assets/t360-header-logo-purple.svg`;
export const hubwiseLogo = `${process.env.PUBLIC_URL}/assets/hubwise-logo.svg`;
export const haloLogo = `${process.env.PUBLIC_URL}/assets/halo-invest-logo-grey.svg`;

export const searchIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-search-white.svg`;
export const askAliceIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-ask-alice-white.svg`;
export const alertIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-alert-white.svg`;
export const adminRoomsIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-admin-rooms-white.svg`;
export const searchIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-search-blue.svg`;
export const askAliceIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-ask-alice-blue.svg`;
export const alertIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-alert-blue.svg`;
export const notificationIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-notifications-white.svg`;
export const notificationIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-notifications-blue.svg`;
export const adminRoomsIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-admin-rooms-blue.svg`;

export const StyledMenuIcon = styled(MenuIcon)`
  margin-right: 10px;
  @media (min-width: 769px) {
    display: none !important;
  }
`;

export const StyledHeader = styled.header<{ headerStyle: HeaderStyle }>`
  position: sticky;
  top: 0;
  z-index: 9;
  color: ${(props) =>
    [HeaderType.DEFAULT, HeaderType.FUNDS].includes(props.headerStyle)
      ? props.theme.colors.WHITE
      : props.theme.colors.BLACK[400]};
  background: ${(props) =>
    [HeaderType.DEFAULT].includes(props.headerStyle)
      ? "linear-gradient(180deg, #4A82A6 90%, #5ECCD9 116.67%)"
      : 
      [HeaderType.FUNDS].includes(props.headerStyle) 
      ? "linear-gradient(180deg, #4A82A6 90%, #5ECCD9 116.67%)" 
      : props.theme.colors.WHITE
    };
  display: ${(props) => (props.headerStyle === HeaderType.NONE ? "none" : "flex")};
  justify-content: space-between;
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
export const StyledHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledLogo = styled.div<{ iconURL: string, headerStyle: HeaderStyle, isFundPartyManager?: boolean, isInstrumentsManager?: boolean }>`
  width: ${(props) =>
    props.isFundPartyManager 
      ? 
      props.isInstrumentsManager
        ? "44px"
        : "70px"
      : "28px"
  };
  height: ${(props) =>
   props.isFundPartyManager
      ? 
        props.isInstrumentsManager
        ? "44px"
        : "55px"
      : "30px"
  };
  display: inline-block;
  vertical-align: middle;
  background-image: ${(props) => `url(${props.iconURL})`};
  margin: 0 14px 0 0;
  background-size: cover;
`;
export const StyledHeaderTitle = styled.div<{ headerStyle: HeaderStyle }>`
  margin-top: 5px;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${(props) =>
    [HeaderType.DEFAULT, HeaderType.FUNDS].includes(props.headerStyle)
      ? "#fff"
      : props.theme.colors.BLACK[400]
  };
  width: ${(props) =>
    [HeaderType.FUNDS].includes(props.headerStyle)
      ? "180px"
      : "150px"
  };
  text-align: center;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const IconsContainer = styled.div<{ iconUrl: string, headerStyle: HeaderStyle }>`
  width:${(props) =>
    [HeaderType.DEFAULT].includes(props.headerStyle)
      ? "24px"
      : 
      [HeaderType.FUNDS].includes(props.headerStyle) 
      ? "30px"
      : "24px"
    };
  height: ${(props) =>
    [HeaderType.DEFAULT].includes(props.headerStyle)
      ? "24px"
      : 
      [HeaderType.FUNDS].includes(props.headerStyle) 
      ? "30px"
      : "24px"
    };
  background-image: ${(props) => `url(${props.iconUrl})`};
  background-size: cover;
  cursor: pointer;
`;

export const IconsSeparator = styled.div`
  margin-right: 24px;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const ProfileIconWrapper = styled.div``;
