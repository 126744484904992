import { FunctionComponent, useState } from "react";
import { 
    BottomContainer,
    BottomNavContainer,
    BottomNavItem,
    BottomCompContainer
} from "styles/funds/Onboarding.styled";
import ClientRequestsOnboardingTable from "./ClientRequestsOnboardingTable";
import { ONBOARDING_TABS, ONBOARDING_TAB } from "constants/hubwiseDashboard";
import FundDetailsConfirmationComponent from "components/detailsConfirmationForm/DetailsConfirmationForm";
import fundDataRequestMockTemplate from "../../../data/fundDataRequestMockTemplate.json";
import { ClientRequestTemplateType } from "types/party";

const NavBox: FunctionComponent<{data: ClientRequestTemplateType[]}> =  ({data}) => {

    const [tab, setTab] = useState<ONBOARDING_TAB>(ONBOARDING_TAB.FUND_REQUEST);

    return (            
        <BottomContainer>
            <BottomNavContainer>
                {ONBOARDING_TABS.map((tabl)=>(
                    <BottomNavItem 
                        key={tabl} 
                        isActive={tab === tabl} 
                        onClick={()=>setTab(tabl)}
                    > 
                        {tabl}
                    </BottomNavItem>

                ))}
            </BottomNavContainer>
            <BottomCompContainer>
                {tab === ONBOARDING_TAB.FUND_REQUEST && <ClientRequestsOnboardingTable data={data}/>}  
                {tab === ONBOARDING_TAB.FUND_LINK && <FundDetailsConfirmationComponent data={fundDataRequestMockTemplate[0]} allFundsData={[]} title="Fund Data Request" hideProgress hideSubmit hideTitleSection hideNavSection/>}   
            </BottomCompContainer>
        </BottomContainer>
    )
};

export default NavBox;