import { useEffect, useState } from "react";
import { AllFundManagerInfo } from "types/party";
import { Link } from "react-router-dom";
import { OpenPartyIcon } from "styles/searchResult/SearchResultsItem.styled";
import { 
  AccordionActionButton,
  FundManagerItemLeftArea,
  FundManagerItemRightArea,
  RightIcons,
  CloseAccordionIcon,
  OpenAccordionIcon,
  FundsManagerContainer, 
  FundManagerName,
  FundDiv,
  FundName,
  IdsHolder,
  IdsDiv,
  FundRequestFormContainer,
  AccordionTitleHolder,
  FundDivHolder,
  RingfencedIcon,
  TileAndIcon
} from "styles/funds/FundManagerList.styled";
import { FundType, FundDataRequestFormType, FundDetailsType, OpenIndexFundsType } from "types/funds";
import { FormValue } from "styles/party/funds/ManageFunds.styled";
import { Dispatch, SetStateAction } from "react";
import DetailsConfirmationComponent from "components/detailsConfirmationForm/DetailsConfirmationForm";
import {callGCFunctionWIthAccessToken} from "services/callGCFunction";
import {
  FUNDS_DATA_REPOSITORY_BUCKET_NAME,
  FUNDS_PATH
} from "config/googleCloudConfig";
import { getAccessToken } from "services/auth0/auth0";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import LoadingSpinner from "components/LoadingSpinner";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import useFilters from "hooks/useFilter";
import { InstrumentsManagerType } from "types/party";

export function FundManagerList({
  data, 
  fundsToAdd,
  setFundsToAdd,
  setAnyFundSelected,
  toggleAll,
  searchStr,
  fundManagers,
  clientId
}:{
  data: InstrumentsManagerType,
  fundsToAdd: {[key: string] : {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string}[]}[]},
  setFundsToAdd: Dispatch<SetStateAction<{[key: string] : {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string}[]}[]}>>,
  setAnyFundSelected: Dispatch<SetStateAction<boolean>>,
  toggleAll: boolean,
  searchStr?: string,
  fundManagers : InstrumentsManagerType[],
  clientId: string | undefined
}) {
  const [showItem, setShowItem] = useState(true);
  const [openIndexes, setOpenIndexes] = useState<OpenIndexFundsType[]>([]);
  const [currentIndex, setCurrentIndex]= useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const token = getAccessToken();
  const { secondSetOfFilteredData, setFilter } = useFilters(fundManagers, data["Instruments List"]);
  useEffect(()=>{
    setFilter({ secondSearch: searchStr });
  },[searchStr])

  const handleCheckboxChange = (fund: FundType, PID: string) => {

    setFundsToAdd((prevState)=>{
      let updatedState= { ...prevState };

      if (clientId) {
        if(updatedState.hasOwnProperty(clientId)) {
          let itemFound= false;
          
          const newStateArray: {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string}[]}[] = prevState[clientId].reduce((acc,item)=>{
            if (item[PID]) {
              itemFound= true;
              const updatedFundsList = item[PID].some((item) => item["Record Id"] === fund["Record Id"])
                ? item[PID].filter((s) => s["Record Id"] !== fund["Record Id"])
                : [...item[PID], {"Record Id": fund["Record Id"], ISIN: fund.ISIN, "SEDOL": fund.SEDOL}];

              if (updatedFundsList.length > 0) {
                acc.push({[PID]: updatedFundsList})
              }
            } else {
              acc.push(item);
            }
            return acc;
          }, [] as {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string}[]}[])

          updatedState= { ...prevState,
            [clientId]: newStateArray
          }

          if(!itemFound) {
            updatedState= { ...prevState,
              [clientId]: [ ...prevState[clientId], { [PID]: [{"Record Id": fund["Record Id"], "ISIN": fund.ISIN, "SEDOL": fund.SEDOL}]}]
            }     
          }
        } else {
          updatedState= { ...prevState,
            [clientId]: [{ [PID]: [{"Record Id": fund["Record Id"], "ISIN": fund.ISIN, "SEDOL": fund.SEDOL}]}]
          };
        }
      }
      return updatedState;
    })

    if(clientId){
      if(fundsToAdd[clientId] && fundsToAdd[clientId].length === 0){
        setAnyFundSelected(false);
      } else {
        setAnyFundSelected(true);
      }
    }
  }

  const handleToggle = (index: number) => {
    setCurrentIndex(index);
    const currentAccordion = openIndexes.find(acc => acc.index === index);

    if (currentAccordion) {
      // If the accordion is already open, close it
      setOpenIndexes(
        openIndexes.map(acc =>
          acc.index === index ? { ...acc, isOpen: !acc.isOpen } : acc
        )
      );
    } else {
      // If the accordion is closed, open it
      setLoading(true);
      const fileName= `${data["Instruments List"][index]["Record Id"]}.json`;
      const payload = {
        "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
        "fileName": fileName,
        "filePath": `${FUNDS_PATH}/${data.PID}`
      }
      callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
      .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
      .then((fundData: FundDetailsType) => {
        const newObj: FundDataRequestFormType= {
          "Available via Morningstar Y/N": fundData["Available via Morningstar"],
          "Currency (GBP, EUR,USD)": fundData.Currency,
          "Trading frequency i.e. Daily or weekly (Hubwise cannot support weekly trading)": fundData["Trading Frequency"],
          "STP (Tradeable via EMX/CALASTONE/Both)": fundData.STP,
          "Settlement Bank/Account/Sort Code (Hubwise can only support Faster Payment to UK Bank)": fundData["Settlement Bank Account"],
          "Clean/Dirty Shareclass (Hubwise cannot support Dirty Shareclasses)": `${fundData["Clean Share Class"]}/ ${fundData["Dirty Share Class"]}`,
          "Dividend Frequency (Hubwise cannot support Daily Dividends)": fundData["Dividend Frequency"],
          "ISIN Code": fundData.ISIN,
          "Fund Name": fundData["Fund Full Name"],
          "Fund Provider Name": fundData["Fund Provider Name"],
          "Fund Administrator Name": fundData["Fund Administrator Name"],
          "SEDOL": fundData.SEDOL,
          "Share Class": fundData["Share Class"],
          "Confirmation to use existing account [Insert Number]/New Account Required?": "",
          "Restricted Shareclass  (i.e. ringfencing* required) Y/N *Hubwise can ringfence fund to firm level": fundData["Restricted Share Class"],
          "CREST Settlement? Y/N": fundData["CREST Settlement"],
          "IA Sector": fundData["IA Sector"],
          "Status Open/Soft Closed": fundData.Status,
          "ON / OFFSHORE": fundData["On/Offshore"],
          "Income or Accumulation": fundData["Income or Accumulation"],
          "Standard Initial Charge %": fundData["Standard Initial Charge %"],
          "Hubwise Initial Charge %": fundData["Hubwise Initial Charge %"],
          "OCF %": fundData["Coalesce OCF %"],
          "Settlement period (T+) (Hubwise cannot support T+0)": fundData["Settlement period (T+)"],
          "Fund Access Requirements Min investment/Min holding/Min top up/ OR Please state whether they are Waived" : fundData["Fund Access Requirements"],
          "Tradeable via Telephone (Insert Tel No.)": fundData["Tradeable via Telephone"],
          "Tradeable via fax (Insert Fax No.)": fundData["Tradeable via Fax"],
          "Calastone Identifier": fundData.Calastone,
          "EMX Product Code": fundData["EMX Product Code"],
          "EMX Provider Code": fundData["EMX Provider Code"],
          "Trade Cut Off Point": fundData["Trade Cut Off Point"],
          "Valuation Point": fundData["Valuation Point"],
          "Administrator Portal Available Y/N (Statements/valuations/reporting)": fundData["Administrator Portal Available"]
        }
        setOpenIndexes([...openIndexes, { index, formInfo: newObj, isOpen: true }]);
        setLoading(false);
      })      
    }
  };

  useEffect(()=>{
    if(!toggleAll) {
      setShowItem(false);
    } else {
      setShowItem(true);
    }
  },[toggleAll])

  return (
    <FundsManagerContainer> 
      <FundManagerItemLeftArea onClickCapture={() => setShowItem(true)}>
        <FundManagerName>{data["Legal Name [PID]"]}</FundManagerName> 
        <RightIcons>
          <AccordionActionButton>
            <Link to={`/funds/${data.PID}`} target="_blank" rel="noreferrer">
              <OpenPartyIcon
                  title={"Open Funds Profile"}
              />
            </Link>
          </AccordionActionButton>
          <AccordionActionButton onClick={() => setShowItem(!showItem)}>
            {showItem === true ? (
              <CloseAccordionIcon title="Close this result" />
            ) : (
              <OpenAccordionIcon title="Open this result" />
            )}
          </AccordionActionButton>
        </RightIcons>
      </FundManagerItemLeftArea>
      <FundManagerItemRightArea>
        {showItem && secondSetOfFilteredData.length > 0 && (
          secondSetOfFilteredData.map((fund: FundType, index: number) =>(
            <FundDivHolder key= {index}>
              <FormValue>
                <input key={`${index}-${fund["Record Id"]}`} type="checkbox" name="add" value={"Add to Fund Link"} onChange={() => handleCheckboxChange(fund, data.PID)}/> Add to Fund Link
              </FormValue>
              <FundDiv>
                <AccordionTitleHolder>
                  <TileAndIcon>
                    <RingfencedIcon ringfenced={fund["Ring-fenced"] === "true" ? true : false}/>
                    <FundName onClick={() => handleToggle(index)}>{fund["Fund Full Name"]}</FundName>
                  </TileAndIcon>
                 <AccordionActionButton onClick={() => handleToggle(index)}>
                    {openIndexes.some(acc => acc.index === index && acc.isOpen === true) ? (
                      <CloseAccordionIcon title="Close form" />
                    ) : (
                      <OpenAccordionIcon title="Open form" />
                    )}
                  </AccordionActionButton>
                </AccordionTitleHolder>
                <IdsHolder>
                  {fund["FIGI"] && 
                    <IdsDiv>
                      <p>{"FIGI: "}</p>
                      <p>{fund["FIGI"]}</p>
                    </IdsDiv>
                  }
                  {fund["ISIN"] && 
                    <IdsDiv>
                      <p>{"ISIN: "}</p>
                      <p>{fund["ISIN"]}</p>
                    </IdsDiv>
                  }
                  {fund["SEDOL"] &&
                    <IdsDiv>
                      <p>{"SEDOL: "}</p>
                      <p>{fund["SEDOL"]}</p>
                    </IdsDiv>
                  }

                </IdsHolder>
                <FundRequestFormContainer>
                  {loading && currentIndex === index &&
                    <LoadingSpinnerDiv>
                      <LoadingSpinner />
                    </LoadingSpinnerDiv>
                  }
                  {openIndexes.some(acc => acc.index === index && acc.isOpen === true ) &&
                    <DetailsConfirmationComponent 
                      key={index} 
                      data={openIndexes.find(acc => acc.index === index)?.formInfo} 
                      title="" 
                      hideSubmit
                      hideProgress
                      hideTitleSection
                      hideNavSection
                      allFundsData={[]}
                    />
                  }
                </FundRequestFormContainer>
              </FundDiv>
            </FundDivHolder>
          ))
        )}
      </FundManagerItemRightArea>
    </FundsManagerContainer>
  );
}
