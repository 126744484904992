// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the main component for Party Profile
 */

import { FunctionComponent } from "react";
import { Accordion } from "components/party/accordion/Accordion";
import { HWFundsType } from "types";
import { Flex, ListCover, InfoList } from "styles/party/funds/index.styled";
import { FundDocumentsType } from "types/funds";
import { 
  FundProfileD, 
  FundIdentifiersD,
  FundKeyInformationD,
  FundChargesD,
  FundOtherInformationD 
} from "constants/party";
import TopHoldings from "./TopHoldings";
import List from "./List";
import FundsDocument from "./FundsDocument";
import { PortfolioBreakdown } from "../portfolioBreakdown/PortfolioBreakdown";
import Sector from "./Sector";
import AssetClass from "./AssetClass";

const FundProfile = (obj: HWFundsType) => {
  const res: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (FundProfileD.includes(key)) {
      res[key] = value;
    }
  }
  return res;
};

const FundIdentifiers = (obj: HWFundsType) => {
  const res: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (FundIdentifiersD.includes(key)) {
      res[key] = value;
    }
  }
  return res;
};

const FundKeyInformation = (obj: HWFundsType) => {
  const res: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (FundKeyInformationD.includes(key)) {
      res[key] = value;
    }
  }
  return res;
};

const FundCharges = (obj: HWFundsType) => {
  const res: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (FundChargesD.includes(key)) {
      res[key] = value;
    }
  }
  return res;
};

const FundOtherInformation = (obj: HWFundsType) => {
  const res: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (FundOtherInformationD.includes(key)) {
      res[key] = value;
    }
  }
  return res;
};

const GroupedDetails: FunctionComponent<{
  data: any;
  fundDocuments: FundDocumentsType;
  fundId?: string;
}> = ({ 
  data, 
  fundDocuments,
  fundId
 }) => {
  if (!data) return null;
  return (
    <div>
      <Accordion title="Profile" light noTopBorder opened>
        <ListCover>
          <List data={FundProfile(data)} title="" fundId = {fundId} twoColumns/>
          <List data={FundIdentifiers(data)} title="Identifiers" fundId= {fundId} twoColumns/>
          <InfoList>
            <List data={FundKeyInformation(data)} title="Key Information" fundId={fundId} />
            <List data={FundCharges(data)} title="Charges" fundId={fundId} />
          </InfoList>
        </ListCover>
      </Accordion>

      <Accordion title="Portfolio Breakdown" light noTopBorder opened>
        <>
          <Flex mb>
            {/* <InfoList>
              <PortfolioBreakdown data={data || []} />
            </InfoList> */}

            <TopHoldings
              data={data?.["Top 10 Holdings Json Data"] ? JSON.parse(data?.["Top 10 Holdings Json Data"]) : []}
              title="Top 10 Holdings"
            /> 
            <Sector
              data={data?.["IA Sector Json Data"] ? JSON.parse( data?.["IA Sector Json Data"] ) : []}
              title="Sector"
            />   
            <AssetClass 
              data={data?.["Asset Class Json Data"] ? JSON.parse( data?.["Asset Class Json Data"] ) : []}
              title="Asset Class"
            />
          </Flex>   
          <ListCover>
            <List data={FundOtherInformation(data)} title="Other Information" fundId = {fundId} twoColumns/>
          </ListCover>    
        </>
      </Accordion>

      <Accordion title="Fund Documents" light noTopBorder opened>
        <FundsDocument data={fundDocuments} />
      </Accordion>
    </div>
  );
};

export default GroupedDetails;
