import { FunctionComponent, useState, useEffect } from "react";
import { 
    UserDesktopViewContainer,
    WidgetsContainer 
} from "styles/funds/UserDesktopView.styled";
import AddWidgetComponent from "./widgets/AddWidget";
import { getItem } from "utils/localStorage";
 import AverageOnboardingTime from "./onboarding/AverageOnboardingTime";
 import CcIdProgress from "./onboarding/CcIdProgress";
 import WidgetComponent from "./widgets/WidgetComponent";
 import AssetOnboardingSummaryCategory from "./clientTemplateRequest/AssetOnboardingSummaryCategory";
import LoadingSpinner from "components/LoadingSpinner";
import { useAppData } from "hooks/useAppData";
import { ClientRequestTemplateType } from "types/party";
import { getAccessToken } from "services/auth0/auth0";
import {
    FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    CRTS_CCIDS_BUYLISTS_PATH,
} from "config/googleCloudConfig";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";

const UserDesktopViewComponent: FunctionComponent =  () => {
    const [userPreferences, setUserPreferences] = useState<string[]>([]);
    const [globalPreferences, setGlobalPreferences]= useState<{email: string, preferences: []}[]>([]);
    const [crtData, setCrtData]= useState<ClientRequestTemplateType[]>();
    const [isOption,setIsOption] = useState<boolean>(false);
    const [activeId, setActiveId]= useState<number>();
    const [updated, setUpdated]= useState<boolean>(false);
    const [isLoading, setIsLoading]= useState<boolean>(false);
    const { user } = useAppData();
    const token = getAccessToken();

    useEffect(()=>{
        if(updated) {
            setIsLoading(true);
            const globalPrefferences= getItem("userPreferences", []);
            if(globalPrefferences.length > 0) {
                globalPrefferences.map((item: {email: string, preferences: []}) => {
                    if(item.email === user?.email) {
                        setUserPreferences(item.preferences);
                    }
                })
            } 
            setUpdated(false);
            setIsLoading(false);
        }
    },[updated])

    useEffect(()=>{
        setGlobalPreferences(getItem("userPreferences", []));   
    },[])

    useEffect(()=>{    
        if(token) {
            setIsLoading(true);
            const indexFile = "index.json";
            const payload = {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": indexFile,
                "filePath": `${CRTS_CCIDS_BUYLISTS_PATH}`
            };

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: ClientRequestTemplateType[])=> {
                setCrtData(data);
                setIsLoading(false);
            });
        }
    },[token]);

    useEffect(()=>{
        if(globalPreferences.length > 0) {
            globalPreferences.map((item: {email: string, preferences: string[]}) => {
                if(item.email === user?.email) {
                    setUserPreferences(item.preferences);
                }
            })
        } else {
            setUserPreferences([]);
        }
    },[globalPreferences])
    
    return isLoading ? <LoadingSpinner /> : (
        <UserDesktopViewContainer>
            <AddWidgetComponent userPreferences={userPreferences} setUpdated= {setUpdated}/>
            <WidgetsContainer>
                <WidgetComponent 
                    isOption={isOption}
                    setIsOption={setIsOption}
                    title="Average Onboarding Completion Time" 
                    element={<AverageOnboardingTime data={crtData} showTitle={false}/>}
                    display={userPreferences.find(acc => acc === "Onboarding Time") ? "true" : "false"}
                    id={1}
                    activeId= {activeId}
                    setActiveId = {setActiveId}
                    userPreferences= {userPreferences}
                    name= "Onboarding Time"
                    setUpdated= {setUpdated}
                />
                <WidgetComponent 
                    isOption={isOption}
                    setIsOption={setIsOption}
                    title="CCID Progress" 
                    element={<CcIdProgress data={crtData} showTitle={false}/>}
                    display={userPreferences.find(acc => acc === "CCID Progress") ? "true" : "false"}
                    id={2}
                    activeId= {activeId}
                    setActiveId = {setActiveId}
                    userPreferences= {userPreferences}
                    name= "CCID Progress"
                    setUpdated= {setUpdated}
                />
                <WidgetComponent 
                    isOption={isOption}
                    setIsOption={setIsOption}
                    title="Asset Onboarding Request" 
                    element={<AssetOnboardingSummaryCategory 
                        fundManagers={0} 
                        funds={0}
                        isMyView = {true}
                    /> }
                    display={userPreferences.find(acc => acc === "Asset Onboarding") ? "true" : "false"}
                    id={3}
                    activeId= {activeId}
                    setActiveId= {setActiveId}
                    userPreferences= {userPreferences}
                    name= "Asset Onboarding"
                    setUpdated= {setUpdated}
                />
            </WidgetsContainer>
        </UserDesktopViewContainer>
    )
};

export default UserDesktopViewComponent