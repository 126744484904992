import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import HubwiseMasterBuylistComponent from "components/hubwise-dashboard/masterBuylist/HubwiseMasterBuylist";
import { useAppData } from "hooks/useAppData";
import { PARTY } from "constants/index";
import NoData from "components/noData/NoData";
import { getAccessToken } from "services/auth0/auth0";
import { useEffect, useState } from "react";
import {
    FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    FUNDS_PATH,
  } from "config/googleCloudConfig";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import { MasterInstrumentManagerType } from "types/funds";
import LoadingSpinner from "components/LoadingSpinner";

const MasterBuylist = () => {
    const [ loading, setLoading ]= useState<boolean>(false);
    const [masterList, setMasterList]= useState<MasterInstrumentManagerType[]>([]);
    const { isUserPartOfOrganization } = useAppData();
    const { track } = useTrackActivity();
    const token = getAccessToken();

    useEffect(()=>{    
        if(token) {
            setLoading(true);
            const indexFile = "data.json";
            const payload = {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": indexFile,
                "filePath": `${FUNDS_PATH}`
            };

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: MasterInstrumentManagerType[])=> {
                setMasterList(data);
                setLoading(false);
            });
        }
    },[token]);

    useEffectOnce(()=>{
        track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD_MASTER_BUYLIST);
    });
    return (
        isUserPartOfOrganization([PARTY.T360]) ? <NoData /> 
        : isUserPartOfOrganization([PARTY.HUBWISE]) ?
        loading ? <LoadingSpinner/> : <HubwiseMasterBuylistComponent data={masterList}/> 
        : <NoData />
    )
     
}

export default MasterBuylist;