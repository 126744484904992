import { FunctionComponent, useState, useEffect } from "react";
import {
    HeaderTextBig,
    HeaderText,
  } from "../../../styles/admin/AliceRequest.styled";
import { 
    TopContainer,
    TopBottomContainer,
    Header
} from "styles/funds/Onboarding.styled";
import AverageOnboardingTime from "./AverageOnboardingTime";
import CcIdProgress from "./CcIdProgress";
import { ClientRequestTemplateType } from "types/party";
import NoData from "components/noData/NoData";

const OnboardingBox: FunctionComponent<{data: ClientRequestTemplateType[]}> =  ({data}) => {

    return data ? (
        <TopContainer>
            <Header data-testid="onboarding-box-header">
                <HeaderText>
                    <HeaderTextBig>Onboarding</HeaderTextBig>
                </HeaderText>
            </Header>
            <TopBottomContainer>
                <AverageOnboardingTime data={data} showTitle boxShadow/>
                <CcIdProgress data={data} showTitle boxShadow/> 
            </TopBottomContainer>
        </TopContainer>
    ) : <NoData />
};

export default OnboardingBox;