import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OpenPartyIcon } from "styles/searchResult/SearchResultsItem.styled";
import { 
  AccordionActionButton,
  FundManagerItemLeftArea,
  FundManagerItemRightArea,
  RightIcons,
  CloseAccordionIcon,
  OpenAccordionIcon,
  FundsManagerContainer, 
  FundManagerName,
  FundDiv,
  FundName,
  IdsHolder,
  IdsDiv,
  FundRequestFormContainer,
  AccordionTitleHolder,
  FundDivHolder,
  TileAndIcon
} from "styles/funds/FundManagerList.styled";
import { FundDetailsType } from "types/funds";
import {callGCFunctionWIthAccessToken} from "services/callGCFunction";
import {
  FUNDS_DATA_REPOSITORY_BUCKET_NAME,
  FUNDS_PATH,
  FUNDS_DOCUMENTS_PATH
} from "config/googleCloudConfig";
import { getAccessToken } from "services/auth0/auth0";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import LoadingSpinner from "components/LoadingSpinner";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import useFilters from "hooks/useFilter";
import { MasterInstrumentManagerType, MasterInstrumentItemType, OpenIndexMasterListType } from "types/funds";
import GroupedDetails from "components/party/funds/GroupedDetails";
import { Container } from "styles/party/funds/index.styled";
import { FundDocumentsType } from "types/funds";

export function HubwiseFundManagerList({
  data, 
  searchStr
}:{
  data: MasterInstrumentManagerType,
  searchStr?: string
}) {
  const [showItem, setShowItem] = useState(true);
  const [openIndexes, setOpenIndexes] = useState<OpenIndexMasterListType[]>([]);
  const [currentIndex, setCurrentIndex]= useState<number>();
  const [fundDocuments, setFundDocuments] = useState<FundDocumentsType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const token = getAccessToken();
  const { secondSetOfFilteredData, setFilter } = useFilters([], data["Fund Details"]);
  useEffect(()=>{
    setFilter({ secondSearch: searchStr });
    setOpenIndexes(
      openIndexes.map(acc => acc.isOpen ? { ...acc, isOpen: false} : acc)
    )
  },[searchStr])

  const handleToggle = (index: number) => {
    setCurrentIndex(index);
    const currentAccordion = openIndexes.find(acc => acc.index === index);

    if (currentAccordion) {
      // If the accordion is already open, close it
      setOpenIndexes(
        openIndexes.map(acc =>
          acc.index === index ? { ...acc, isOpen: !acc.isOpen } : acc
        )
      );
    } else {
      // If the accordion is closed, open it
      setLoading(true);
      const fileName= `${data["Fund Details"][index]["Record Id"]}.json`;
      const payload = {
        "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
        "fileName": fileName,
        "filePath": `${FUNDS_PATH}/${data.PID}`
      }
      callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
      .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
      .then((fundData: FundDetailsType) => {
        setOpenIndexes([...openIndexes, { index, fundInfo: fundData, isOpen: true }]);
        setLoading(false);
      })   
      const fileNameForDocs = `${data["Fund Details"][index]["Record Id"]}.json`;
      const payloadForFundsDocs = {
        "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
        "fileName": fileNameForDocs,
        "filePath": `${FUNDS_DOCUMENTS_PATH}/${data["Fund Details"][index]["Record Id"]}`
    }

    if(token) {
        callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payloadForFundsDocs, "test", "test")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data) => {
            console.log("fund docs: ", data);
        })
    }   
    }
  };

  return (
    <FundsManagerContainer> 
      <FundManagerItemLeftArea onClickCapture={() => setShowItem(true)}>
        <FundManagerName>{data["Legal Name [PID]"]}</FundManagerName> 
        <RightIcons>
          <AccordionActionButton>
            <Link to={`/funds/${data.PID}`} target="_blank" rel="noreferrer">
              <OpenPartyIcon
                  title={"Open Funds Profile"}
              />
            </Link>
          </AccordionActionButton>
          <AccordionActionButton onClick={() => setShowItem(!showItem)}>
            {showItem === true ? (
              <CloseAccordionIcon title="Close this result" />
            ) : (
              <OpenAccordionIcon title="Open this result" />
            )}
          </AccordionActionButton>
        </RightIcons>
      </FundManagerItemLeftArea>
      <FundManagerItemRightArea>
        
        {showItem && secondSetOfFilteredData.length > 0 && (
          secondSetOfFilteredData.map((fund: MasterInstrumentItemType, index: number) =>(
            <FundDivHolder key= {index}>
              <FundDiv>
                <AccordionTitleHolder>
                  <TileAndIcon>
                    {/* <RingfencedIcon ringfenced={fund["Ring-fenced"] === "true" ? true : false}/> */}
                    <FundName onClick={() => handleToggle(index)}>{fund["Fund Full Name"]}</FundName>
                  </TileAndIcon>
                 <AccordionActionButton onClick={() => handleToggle(index)}>
                    {openIndexes.some(acc => acc.index === index && acc.isOpen === true) ? (
                      <CloseAccordionIcon title="Close form" />
                    ) : (
                      <OpenAccordionIcon title="Open form" />
                    )}
                  </AccordionActionButton>
                </AccordionTitleHolder>
                <IdsHolder>
                  {fund["FIGI"] && 
                    <IdsDiv>
                      <p>{"FIGI: "}</p>
                      <p>{fund["FIGI"]}</p>
                    </IdsDiv>
                  }
                  {fund["ISIN"] && 
                    <IdsDiv>
                      <p>{"ISIN: "}</p>
                      <p>{fund["ISIN"]}</p>
                    </IdsDiv>
                  }
                  {fund["SEDOL"] &&
                    <IdsDiv>
                      <p>{"SEDOL: "}</p>
                      <p>{fund["SEDOL"]}</p>
                    </IdsDiv>
                  }

                </IdsHolder>
                <FundRequestFormContainer>
                  {loading && currentIndex === index &&
                    <LoadingSpinnerDiv>
                      <LoadingSpinner />
                    </LoadingSpinnerDiv>
                  }
                  {openIndexes.some(acc => acc.index === index && acc.isOpen === true ) &&
                    <Container>
                        <GroupedDetails
                            data={openIndexes.find(acc => acc.index === index)?.fundInfo} 
                            fundId= {openIndexes.find(acc => acc.index === index)?.fundInfo["Record Id"]!} 
                            fundDocuments={fundDocuments!}
                        />
                    </Container>
                    }
                </FundRequestFormContainer>
              </FundDiv>
            </FundDivHolder>
          ))
        )}
      </FundManagerItemRightArea>
    </FundsManagerContainer>
  );
}
