// picks a color for progress bars and circles in Onboarding view

export const colorPick = (index: number) => {
    const colors = [
        ["#8f9779","#4d5d53"],
        ["#5b5b5b","#999999"],
        ["#789987","#deffed"],
        ["#38761d","#7ad950"],
        ["#66b2b2","#b2d8d8"],
        ["#884c69","#6a1f44"]
    ]
    
    return colors[index % colors.length]
}