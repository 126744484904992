import { FunctionComponent } from "react";
import {
    BottomContentContainer,
} from "styles/funds/Onboarding.styled";
import useFilters from "hooks/useFilter";
import { Search } from "../../filter/Search";
import { 
    MasterBuylistContainer,
    Title,
    FundManagersListContainer,
    FiltersContainer 
} from "styles/funds/MasterBuylist.styled";
import { MasterInstrumentManagerType } from "types/funds";
import NoData from "components/noData/NoData";
import { useState } from "react";
import { HubwiseFundManagerList } from "./HuwiseMasterBuylistFundManager";

const HubwiseMasterBuylistComponent: FunctionComponent<{data: MasterInstrumentManagerType[]}> =  ({data}) => {
    const [searchStr, setSearchStr] = useState<string>("");
    const { filteredData, setFilter } = useFilters(data);
    const setData = (str: string) => {
        setFilter({ search: str.trim() });
        setTimeout(()=>{
            setSearchStr(str.trim());
        },1000)
    };
    
    return !data ? <NoData /> : (
        <MasterBuylistContainer>
            <Title>Master Buylist</Title>
            <BottomContentContainer>
                <FiltersContainer>
                    <Search setData={setData} />
                </FiltersContainer>
                <FundManagersListContainer>
                    {filteredData.length && filteredData.map((fundManager: MasterInstrumentManagerType, index) => (
                        <HubwiseFundManagerList
                            key={index}
                            data={fundManager}
                            searchStr={searchStr}
                        />
                    ))}
                </FundManagersListContainer>
            </BottomContentContainer>
        </MasterBuylistContainer>
    )
};

export default HubwiseMasterBuylistComponent;