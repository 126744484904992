import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import ClientTemplateRequestComponent from "components/funds/ClientTemplateRequestComponent";
import { GET_OBJECT_FROM_STORAGE, GET_PARTY_DETAILS } from "config/googleCloudFunctionsConfig";
import {
    FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    CRTS_CCIDS_BUYLISTS_PATH,
  } from "config/googleCloudConfig";
import { CrtType, InstrumentsManagerType, GeneralDetailsType } from "types/party";
import { getAccessToken } from "services/auth0/auth0";
import { useEffect, useState } from "react";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import LoadingSpinner from "components/LoadingSpinner";
import { FundType } from "types/funds";
import { useParams } from "react-router-dom";

const HubwiseClientTemplateRequest = () => {
  const { track } = useTrackActivity();
  const [fundManagersData, setFundManagersData]= useState<InstrumentsManagerType[]>([]);
  const [generalDetails, setGeneralDetails]= useState<GeneralDetailsType>();
  const [allFunds, setAllFunds] = useState<FundType[]>([]);
  const [fundsToAddArray, setFundsToAddArray] = useState<{[key: string]: {"Record Id": string; "ISIN": string; "SEDOL": string}[]}[]>([])
  const [loading, setLoading] = useState<boolean>(true);
  const token = getAccessToken();
  const {id}= useParams();

  useEffectOnce(()=>{
      track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD_ACTIVE_CCIDS);
  });

  useEffect(()=>{    
    if(token) {
      // get CRT information
      const crtFile= `crt_${id}.json`;
      const payloadForCrtFile= {
        "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
        "fileName": crtFile,
        "filePath": `${CRTS_CCIDS_BUYLISTS_PATH}`
      }

      callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payloadForCrtFile, "test", "test")
      .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
      .then((data: CrtType)=>{
        if(data) {
          setFundManagersData(data["Instruments Manager List"]);
          if(data["Instruments Manager List"] && data["Instruments Manager List"].length > 0) {
            data["Instruments Manager List"].map((fundManager) => {
              const newObj: {[key: string]: {"Record Id": string; "ISIN": string; "SEDOL": string}[]}= {[fundManager.PID]:[] }
              if(fundManager["Instruments List"] && fundManager["Instruments List"].length > 0) {
                fundManager["Instruments List"].map((fund) => {
                  const newFundsArray= allFunds;
                  newFundsArray.push(fund);
                  setAllFunds(newFundsArray);
                  newObj[fundManager.PID].push({"Record Id": fund["Record Id"], "ISIN": fund.ISIN, "SEDOL": fund.SEDOL});
                })
                const newArr= fundsToAddArray;
                newArr.push(newObj);
                setFundsToAddArray(newArr);
              }
            })
          }

          // get general party details for banner of CRT page
          const payloadForGeneralDetailsBanner= {
            pid: data["Related Party PID"]
          }

          callGCFunctionWIthAccessToken(token, GET_PARTY_DETAILS, payloadForGeneralDetailsBanner, "test", "test")
          .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
          .then((partyData) => {
            if(partyData?.["General Details"]) {
              setGeneralDetails(partyData?.["General Details"] as GeneralDetailsType);
              setLoading(false);
            }
          })
        }
       
      })}
  },[token])

  return (
    loading ? <LoadingSpinner /> :
    <ClientTemplateRequestComponent 
      dataForCcIdProfile= {generalDetails} 
      fundManagers= {fundManagersData} 
      allFunds= {allFunds}
      fundsToAddArray= {fundsToAddArray}
    /> 
  )       
}

export default HubwiseClientTemplateRequest;