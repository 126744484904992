import styled from "styled-components";

const elipsisIcon = `${process.env.PUBLIC_URL}/assets/elipsis.svg`;
const acceptedIcon = `${process.env.PUBLIC_URL}/assets/confirmed.svg`;

export const OnboardingMainContainer = styled.div<{
  noShadow?: boolean;
  noMarginTop?: boolean;
}>`
  margin-top: 0;
  display: block;
  column-span: all;
  overflow-x: auto;
  margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
  margin-bottom: 60px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 8px;
  padding: 12px 0;
  padding-bottom: 37px;
  white-space: nowrap;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0px;
  }
  &:first-child {
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
  }
  margin-left: 22px;
`;

export const CirclesChartContainer = styled.div<{ boxShadow?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: left;
  box-shadow: ${(props) => props.boxShadow ? "0 0 4px 2px rgba(0, 0, 0, 0.3)" : "none"};
  padding: 12px;
`;

export const CirclesTitle = styled.div`
  width: 100%;
  font-size: 20px;
  margin-bottom: 20px;
`;

export const BarChartHolder = styled.div`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ProgressBar = styled.div`
  width: 80%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10%;
`;

export const BarChartContainer = styled.div<{boxShadow?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: left;
  box-shadow: ${(props) => props.boxShadow ? "0 0 4px 2px rgba(0, 0, 0, 0.3)" : "none"};
  padding: 12px;
`;

export const OptionsHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: right;
  margin-left: 20px;
`;

export const OptionToAdd = styled.div`
  background-color: #EDEFEF;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  width: min-content;
  align-self: end;
  margin-right: 15px;
`;

export const ElipsisHolder = styled.div`
  background: url(${elipsisIcon});
  background-size: cover;
  width: 12px;
  height: 24px;
  cursor: pointer;
  align-self: center;
`

export const BarChartTitle = styled.div`
  width: 100%;
  font-size: 20px;
  margin-bottom: 20px;
`;



export const CirclesChartHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 1097px) {
    grid-template-columns: repeat(2, 1fr);
  }
  width: 100%;
  margin-top: 30px;
`;

export const TopContainer = styled.div`
  width: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export const TopBottomContainer = styled.div`
  width: 92%;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 769px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const BottomContainer = styled.div`
  width: 92%;
  margin-left: 10px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
`;

export const BottomNavContainer = styled.div`
  max-width: 100%;
  margin: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #4A82A6;
  padding: 15px;
`;

export const BottomCompContainer = styled.div`
  width: 96%;
  margin: 0;
  padding: 12px;
`;

export const BottomNavItem = styled.div<{ isActive?: boolean}>`
  width: 11%;
  @media (max-width: 769px) {
    width: 45%;
  }
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.isActive ? "#fff" : "#d2d4d6"};
  border-bottom: ${(props) => props.isActive ? "5px solid #fff" : "none"};
  border-bottom-radius: 5px;
  cursor: pointer;
  text-wrap: wrap;
  margin-right: 50px;
`;

export const BottomContentContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  @media (max-width: 769px) {
    justify-content: start;
  }
`;

export const SearchWrapper = styled.div`
  width: 290px;
`;

export const TableContainer = styled.div`
  max-width: 100%;
  margin-top:30px;
  padding: 10px 15px;
`;

export const T360Actioned = styled.div`
  width: 24px;
  height: 24px;
  background-size: contain;
  background-image: url(${acceptedIcon});
`;

export const NotT360Actioned = styled.div`
  width: 24px;
  height: 24px;
`;

export const Th = styled.th`
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  font-weight: 600;
  padding: 0 24px;
  padding-bottom: 8px;
  text-wrap: nowrap;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
  }
`;
